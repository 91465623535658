var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn vendor-create" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("h4", { staticClass: "d-block mb-4" }, [
                    _c("i", { staticClass: "nav-icon icon-plus" }),
                    _vm._v(" Edit Preset "),
                    _c("i", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            "<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE and cost codes to each line items (This is optional for vendors)</p>",
                          expression:
                            "'<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE and cost codes to each line items (This is optional for vendors)</p>'"
                        }
                      ],
                      staticClass: "ml-2 fa fa fa-question-circle"
                    })
                  ]),
                  _c("h4", [_vm._v("Preset Template Name: ")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.it_name,
                        expression: "it_name"
                      }
                    ],
                    staticClass: "template_name",
                    attrs: { type: "text" },
                    domProps: { value: _vm.it_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.it_name = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "b-row",
                    { staticClass: "mb-4 clearfix" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "col-12 mb-3 col-md-12 col-lg-4" },
                        [
                          _c("h4", { staticClass: "mb-3" }, [
                            _vm._v("Billing From:")
                          ]),
                          _c("hr"),
                          _vm.vendorAvatar
                            ? _c("div", { staticClass: "mt-3 mb-3" }, [
                                _c("img", {
                                  staticClass: "companylogo",
                                  attrs: { src: _vm.vendorAvatar }
                                })
                              ])
                            : _vm._e(),
                          _c("h5", [
                            _vm._v(_vm._s(_vm.vendorDetails.company_name))
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.vendorDetails.fname) +
                                " " +
                                _vm._s(_vm.vendorDetails.lname)
                            )
                          ]),
                          _c(
                            "ul",
                            { staticClass: "fa-ul invoice-detail-lists" },
                            [
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-building" })
                                ]),
                                _vm._v(_vm._s(_vm.vendorDetails.addressline1))
                              ]),
                              _vm.vendorDetails.addressline2
                                ? _c("li", [
                                    _c("span", { staticClass: "fa-li" }, [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.vendorDetails.addressline2)
                                    )
                                  ])
                                : _vm._e(),
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-building" })
                                ]),
                                _vm._v(
                                  _vm._s(_vm.vendorDetails.city) +
                                    ", " +
                                    _vm._s(_vm.vendorDetails.province)
                                )
                              ]),
                              _c("li", { staticClass: "text-uppercase" }, [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-building" })
                                ]),
                                _vm._v(_vm._s(_vm.vendorDetails.postalcode))
                              ]),
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-phone fa-flip-horizontal"
                                  })
                                ]),
                                _vm._v(_vm._s(_vm.vendorDetails.phone_num))
                              ]),
                              _vm.vendorDetails.fax_num
                                ? _c("li", [
                                    _c("span", { staticClass: "fa-li" }, [
                                      _c("i", { staticClass: "fa fa-fax" })
                                    ]),
                                    _vm._v(_vm._s(_vm.vendorDetails.fax_num))
                                  ])
                                : _vm._e(),
                              _c("hr"),
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-university" })
                                ]),
                                _vm._v("GST#: " + _vm._s(_vm.vendorDetails.gst))
                              ]),
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-university" })
                                ]),
                                _vm._v("RCN#: " + _vm._s(_vm.vendorDetails.rcn))
                              ]),
                              _c("li", [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", { staticClass: "fa fa-university" })
                                ]),
                                _vm._v(
                                  "Bank Account#: " +
                                    _vm._s(_vm.vendorDetails.bankaccount)
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("b-col", { staticClass: "col-12 col-md-6 col-lg-4" }, [
                        _c("h4", { staticClass: "mb-3" }, [_vm._v("Bill To:")]),
                        _c("hr"),
                        _vm.avatar
                          ? _c("div", { staticClass: "mt-3 mb-4" }, [
                              _c("img", {
                                staticClass: "companylogo",
                                attrs: { src: _vm.avatar }
                              })
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.companyId,
                                  expression: "companyId"
                                }
                              ],
                              staticClass: "rt-select w-75",
                              attrs: { disabled: _vm.serviceData.length > 0 },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.companyId = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.get_projects
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "0", disabled: "" } },
                                [_vm._v("Select Company")]
                              ),
                              _vm._l(_vm.companyData, function(company) {
                                return _c(
                                  "option",
                                  { domProps: { value: company } },
                                  [_vm._v(_vm._s(company.cn))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm.companyId && !_vm.loadingCompany
                          ? _c(
                              "ul",
                              { staticClass: "fa-ul invoice-detail-lists" },
                              [
                                _c("li", [
                                  _c("span", { staticClass: "fa-li" }, [
                                    _c("i", { staticClass: "fa fa-building" })
                                  ]),
                                  _vm._v(_vm._s(_vm.companyInfo.addressline1))
                                ]),
                                _vm.companyInfo.addressline2
                                  ? _c("li", [
                                      _c("span", { staticClass: "fa-li" }, [
                                        _c("i", {
                                          staticClass: "fa fa-building"
                                        })
                                      ]),
                                      _vm._v(
                                        _vm._s(_vm.companyInfo.addressline2)
                                      )
                                    ])
                                  : _vm._e(),
                                _c("li", [
                                  _c("span", { staticClass: "fa-li" }, [
                                    _c("i", { staticClass: "fa fa-building" })
                                  ]),
                                  _vm._v(
                                    _vm._s(_vm.companyInfo.city) +
                                      ", " +
                                      _vm._s(_vm.companyInfo.province)
                                  )
                                ]),
                                _c("li", [
                                  _c("span", { staticClass: "fa-li" }, [
                                    _c("i", { staticClass: "fa fa-building" })
                                  ]),
                                  _vm._v(_vm._s(_vm.companyInfo.postalcode))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("b-col", { staticClass: "col-12 col-md-6 col-lg-4" }, [
                        _c("h4", { staticClass: "mb-3" }, [_vm._v("Details:")]),
                        _c("hr"),
                        _c("h5", { staticClass: "mb-3" }, [
                          _vm._v("Date:"),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("moment")(new Date(), "MM/DD/YYYY"))
                          )
                        ]),
                        _c("h6", { staticClass: "font-weight-normal" }),
                        _vm.companyId
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("label", [_vm._v("Project Name")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project_name,
                                    expression: "project_name"
                                  }
                                ],
                                staticClass: "rt-select w-75",
                                attrs: {
                                  disabled: _vm.serviceData.length > 0,
                                  name: "",
                                  id: ""
                                },
                                domProps: { value: _vm.project_name },
                                on: {
                                  change: _vm.get_services,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.project_name = $event.target.value
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm.companyId && _vm.vwref
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("label", [_vm._v("Well")]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.vwref,
                                      expression: "vwref"
                                    }
                                  ],
                                  staticClass: "rt-select w-75",
                                  attrs: {
                                    disabled: _vm.serviceData.length > 0
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.vwref = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "1", disabled: "" } },
                                    [_vm._v("Select Well")]
                                  ),
                                  _vm._l(_vm.uwiData, function(option) {
                                    return _c(
                                      "option",
                                      { domProps: { value: option } },
                                      [
                                        option.uwi_3
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(option.uwi_3) +
                                                  ") - "
                                              )
                                            ])
                                          : _vm._e(),
                                        option.uwi_2
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(option.uwi_2) +
                                                  ") - "
                                              )
                                            ])
                                          : _vm._e(),
                                        option.uwi
                                          ? _c("span", [
                                              _vm._v(
                                                "(" + _vm._s(option.uwi) + ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm.vwref !== 1 && _vm.vwref !== "1"
                                ? _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "fa-ul invoice-detail-lists mt-2"
                                    },
                                    [
                                      _c("li", [
                                        _c("span", { staticClass: "fa-li" }, [
                                          _c("i", {
                                            staticClass: "fa fa-hashtag"
                                          })
                                        ]),
                                        _vm._v(
                                          "License Number: " +
                                            _vm._s(_vm.vwref.ln)
                                        )
                                      ]),
                                      _c("li", [
                                        _c("span", { staticClass: "fa-li" }, [
                                          _c("i", {
                                            staticClass: "fa fa-location"
                                          })
                                        ]),
                                        _vm._v(
                                          "Surface Location: " +
                                            _vm._s(_vm.vwref.lsdsurface)
                                        )
                                      ]),
                                      _vm.vwref.lsdbottom !== "----"
                                        ? _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "fa-li" },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-location"
                                                })
                                              ]
                                            ),
                                            _vm._v(
                                              "Bottom Location: " +
                                                _vm._s(_vm.vwref.lsdbottom)
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "service-modal",
                      staticClass: "service-modal",
                      attrs: {
                        centered: "",
                        size: "lg",
                        "hide-footer": "",
                        title: "Select Your Service From The Following"
                      }
                    },
                    [
                      _c("v-client-table", {
                        staticClass: "table vendor-service-select-table",
                        attrs: {
                          columns: _vm.columns,
                          data: _vm.vendorServices,
                          options: _vm.options,
                          id: "dataTable"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "name",
                            fn: function(props) {
                              return _c("div", {}, [
                                _c("span", [
                                  !_vm.vendoreService_toggler.includes(
                                    props.row.serviceid
                                  )
                                    ? _c("input", {
                                        staticClass: "mr-2",
                                        attrs: { type: "checkbox" },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectService(props.row)
                                          }
                                        }
                                      })
                                    : _c("i", {
                                        staticClass: "fa fa-trash mr-2",
                                        staticStyle: { color: "#F64947" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deselectService(
                                              props.row
                                            )
                                          }
                                        }
                                      }),
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(props.row.name) +
                                      "\n                      "
                                  )
                                ])
                              ])
                            }
                          },
                          {
                            key: "type",
                            fn: function(props) {
                              return _c("div", {}, [
                                _c("span", [_vm._v(_vm._s(props.row.type))])
                              ])
                            }
                          },
                          {
                            key: "uom1",
                            fn: function(props) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.uom1 !== null
                                        ? "$" +
                                            props.row.pu1 +
                                            "/" +
                                            props.row.uom1
                                        : "-"
                                    )
                                  )
                                ])
                              ])
                            }
                          },
                          {
                            key: "uom2",
                            fn: function(props) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.uom2 !== null
                                        ? "$" +
                                            props.row.pu2 +
                                            "/" +
                                            props.row.uom2
                                        : "-"
                                    )
                                  )
                                ])
                              ])
                            }
                          },
                          {
                            key: "uom3",
                            fn: function(props) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.uom3 !== null
                                        ? "$" +
                                            props.row.pu3 +
                                            "/" +
                                            props.row.uom3
                                        : "-"
                                    )
                                  )
                                ])
                              ])
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              false
                ? _c("b-card", [
                    _c("h3", { staticClass: "d-inline" }, [_vm._v("Services")]),
                    _c(
                      "div",
                      { attrs: { id: "date-picker-div" } },
                      [
                        _vm.preServiceData.length > 0
                          ? _c("span", [_vm._v("Apply Dates to all services")])
                          : _vm._e(),
                        _vm.preServiceData.length > 0
                          ? _c("v-date-picker", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "vc-calendar",
                              attrs: {
                                id: "date-picker",
                                formats: _vm.formats,
                                mode: "range",
                                "show-caps": ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setDates()
                                }
                              },
                              model: {
                                value: _vm.allService_dates,
                                callback: function($$v) {
                                  _vm.allService_dates = $$v
                                },
                                expression: "allService_dates"
                              }
                            })
                          : _vm._e(),
                        _vm.projectid
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn-blue mb-2 btn-sm float-right",
                                attrs: {
                                  disabled:
                                    _vm.beforeEditCacheCatch == 1 ||
                                    _vm.beforeEditAfeCacheCatch == 1,
                                  href: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addAllLines()
                                  }
                                }
                              },
                              [
                                _vm._v("\n              Save All To Invoice "),
                                _c("i", { staticClass: "fa fa-save" })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "table-responsive-sm clearfix" }, [
                      _c(
                        "form",
                        {
                          staticClass: "position-relative",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.addPreService.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading,
                                  expression: "loading"
                                }
                              ],
                              attrs: { id: "loading-gif-container" }
                            },
                            [
                              _c("rotate-square2", {
                                attrs: { id: "loading-gif" }
                              })
                            ],
                            1
                          ),
                          _c("table", { staticClass: "table table-striped" }, [
                            _c(
                              "thead",
                              { staticClass: "d-none d-xl-table-header-group" },
                              [
                                _c(
                                  "tr",
                                  {
                                    staticClass: "service-rows",
                                    attrs: { id: "service-th" }
                                  },
                                  [
                                    _c("th", { staticClass: "actions fit" }),
                                    _c(
                                      "th",
                                      { staticClass: "center service-name" },
                                      [_vm._v("Service")]
                                    ),
                                    _c(
                                      "th",
                                      { staticClass: "center service-name" },
                                      [_vm._v("Code")]
                                    ),
                                    _c(
                                      "th",
                                      { staticClass: "pre-datepickers right" },
                                      [_vm._v("Start - End Date")]
                                    ),
                                    _c("th", { staticClass: "uom-select" }, [
                                      _vm._v("UOM "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          }
                                        ],
                                        staticClass: "fa fa-question-circle",
                                        attrs: { title: "Unit Of Measurement" }
                                      })
                                    ]),
                                    _c("th", { staticClass: "price center" }, [
                                      _vm._v("Price/Unit")
                                    ]),
                                    _c("th", { staticClass: "price center" }, [
                                      _vm._v("Discounted Price/Unit")
                                    ]),
                                    _c("th", { staticClass: "price center" }, [
                                      _vm._v("Quantity")
                                    ]),
                                    _c("th", { staticClass: "price center" }, [
                                      _vm._v("Discount (%)")
                                    ]),
                                    _c("th", { staticClass: "right" }, [
                                      _vm._v("Total")
                                    ]),
                                    _c("th", { staticClass: "save-button" })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "tbody",
                              _vm._l(_vm.preServiceData, function(service, i) {
                                return _c(
                                  "tr",
                                  {
                                    staticClass: "service-rows create-service"
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "d-xs-none d-lg-block actions fit border-0"
                                      },
                                      [
                                        _vm.preServiceData.length > 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-danger",
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    _vm.beforeEditCacheCatch ==
                                                      1 ||
                                                    _vm.beforeEditAfeCacheCatch ==
                                                      1
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deletePreLineServices(
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-minus"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "center service-name" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "d-xs-block d-xl-none mobile-header"
                                          },
                                          [_vm._v("Service")]
                                        ),
                                        service.selected
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    service.serviceid.name
                                                  ) +
                                                  "\n                        "
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [
                                            _c("multiselect", {
                                              staticClass:
                                                "rt-vendor-service-select",
                                              attrs: {
                                                label: "name",
                                                multiple: false,
                                                tagPlaceholder: "",
                                                "track-by": "serviceid",
                                                selectLabel: "",
                                                deselectLabel: "",
                                                options: _vm.vendorServices
                                              },
                                              on: {
                                                input: function($event) {
                                                  service.uom = [
                                                    service.serviceid.pu1,
                                                    service.serviceid.uom1
                                                  ]
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "option",
                                                    fn: function(props) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "option__desc"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "option__title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    props.option
                                                                      .name
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      props
                                                                        .option
                                                                        .currency
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: service.serviceid,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    service,
                                                    "serviceid",
                                                    $$v
                                                  )
                                                },
                                                expression: "service.serviceid"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "center service-name" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "d-xs-block d-xl-none mobile-header"
                                          },
                                          [_vm._v("Code")]
                                        ),
                                        service.selected
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    service.serviceid.type
                                                  ) +
                                                  "\n                        "
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [
                                            _c("multiselect", {
                                              staticClass:
                                                "rt-vendor-service-select",
                                              attrs: {
                                                label: "type",
                                                tagPlaceholder: "",
                                                multiple: false,
                                                selectLabel: "",
                                                deselectLabel: "",
                                                "track-by": "serviceid",
                                                options: _vm.vendorServices
                                              },
                                              on: {
                                                input: function($event) {
                                                  service.uom = [
                                                    service.serviceid.pu1,
                                                    service.serviceid.uom1
                                                  ]
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "option",
                                                    fn: function(props) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "option__desc"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "option__title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    props.option
                                                                      .type
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: service.serviceid,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    service,
                                                    "serviceid",
                                                    $$v
                                                  )
                                                },
                                                expression: "service.serviceid"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "pre-datepickers right" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Start - End Date")]
                                        ),
                                        service.serviceid.name
                                          ? _c("v-date-picker", {
                                              staticClass: "vc-calendar",
                                              attrs: {
                                                formats: _vm.formats,
                                                mode: "range",
                                                "show-caps": ""
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.setQuantity(i)
                                                }
                                              },
                                              model: {
                                                value: service.dates,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    service,
                                                    "dates",
                                                    $$v
                                                  )
                                                },
                                                expression: "service.dates"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "left uom-select" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Unit Of Measurement")]
                                        ),
                                        service.serviceid.name
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'"
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: service.uom,
                                                    expression: "service.uom"
                                                  }
                                                ],
                                                staticClass: "w-100 rt-select",
                                                class: {
                                                  "is-danger": _vm.errors.has(
                                                    "service.uom"
                                                  )
                                                },
                                                attrs: {
                                                  name: "service.uom",
                                                  placeholder: "Select"
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        service,
                                                        "uom",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.Pu_toggler(
                                                        service,
                                                        service.discount,
                                                        service.uom[0]
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { selected: "" },
                                                    domProps: {
                                                      value: [
                                                        service.serviceid.pu1,
                                                        service.serviceid.uom1
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        service.serviceid.uom1
                                                      )
                                                    )
                                                  ]
                                                ),
                                                service.serviceid.pu2
                                                  ? _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: [
                                                            service.serviceid
                                                              .pu2,
                                                            service.serviceid
                                                              .uom2
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            service.serviceid
                                                              .uom2
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                service.serviceid.pu3
                                                  ? _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: [
                                                            service.serviceid
                                                              .pu3,
                                                            service.serviceid
                                                              .uom3
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            service.serviceid
                                                              .uom3
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "price left v-align-middle"
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none",
                                            staticStyle: {
                                              "margin-bottom": "13px"
                                            }
                                          },
                                          [_vm._v("Price")]
                                        ),
                                        service.serviceid.name
                                          ? _c("span", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      service.uom[0]
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "price left v-align-middle"
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none",
                                            staticStyle: {
                                              "margin-bottom": "13px"
                                            }
                                          },
                                          [_vm._v(" Discounted Price/unit")]
                                        ),
                                        service.serviceid.name
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    service.discounted_price_per_unit,
                                                  expression:
                                                    "service.discounted_price_per_unit"
                                                }
                                              ],
                                              staticStyle: { width: "110px" },
                                              domProps: {
                                                value:
                                                  service.discounted_price_per_unit
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      service,
                                                      "discounted_price_per_unit",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.Discount(
                                                      service,
                                                      service.discounted_price_per_unit,
                                                      service.uom[0]
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "price left",
                                        staticStyle: {
                                          "padding-bottom": "0.5rem"
                                        }
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Quantity")]
                                        ),
                                        service.serviceid.name
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: service.quantity,
                                                  expression: "service.quantity"
                                                }
                                              ],
                                              staticClass: "rt-round",
                                              class: {
                                                warning:
                                                  /day|Day/.test(service.uom) &&
                                                  _vm
                                                    .$moment(service.dates.end)
                                                    .diff(
                                                      _vm.$moment(
                                                        service.dates.start
                                                      ),
                                                      "days"
                                                    ) +
                                                    1 !=
                                                    service.quantity
                                              },
                                              attrs: { step: "0.01" },
                                              domProps: {
                                                value: service.quantity
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    service,
                                                    "quantity",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        /day|Day/.test(service.uom) &&
                                        _vm
                                          .$moment(service.dates.end)
                                          .diff(
                                            _vm.$moment(service.dates.start),
                                            "days"
                                          ) +
                                          1 !=
                                          service.quantity
                                          ? _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover",
                                                      modifiers: { hover: true }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "fa fa-exclamation-triangle",
                                                  attrs: {
                                                    title:
                                                      "Quantity does not equal sum between start and end (" +
                                                      (parseInt(
                                                        _vm
                                                          .$moment(
                                                            service.dates.end
                                                          )
                                                          .diff(
                                                            _vm.$moment(
                                                              service.dates
                                                                .start
                                                            ),
                                                            "days"
                                                          )
                                                      ) +
                                                        1) +
                                                      ")!"
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "price left",
                                        staticStyle: {
                                          "padding-bottom": "0.5rem"
                                        }
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Discount (%)")]
                                        ),
                                        service.serviceid.name
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: service.discount,
                                                  expression: "service.discount"
                                                }
                                              ],
                                              staticClass: "rt-round",
                                              staticStyle: { width: "110px" },
                                              attrs: {
                                                type: "text",
                                                id:
                                                  "" +
                                                  (service.serviceid.serviceid +
                                                    service.uom[0])
                                              },
                                              domProps: {
                                                value: service.discount
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      service,
                                                      "discount",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.discounted(
                                                      service,
                                                      service.discount,
                                                      service.uom[0]
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              delay: "1000000",
                                              show:
                                                service.discount > 100 ||
                                                service.discount < 0,
                                              target:
                                                "" +
                                                (service.serviceid.serviceid +
                                                  service.uom[0]),
                                              title: "Warning"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Discount should be between 0%-100%"
                                              )
                                            ]),
                                            _c("hr"),
                                            _c("span", [
                                              _vm._v(
                                                "Discounted price per unit should be between $0-price/unit"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "right v-align-middle" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none",
                                            staticStyle: {
                                              "margin-bottom": "13px"
                                            }
                                          },
                                          [_vm._v("Total")]
                                        ),
                                        service.serviceid.name
                                          ? _c("span", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      service.quantity *
                                                        service.uom[0] -
                                                        (service.discount /
                                                          100) *
                                                          service.quantity *
                                                          service.uom[0]
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "save-button",
                                        staticStyle: { "margin-left": "65px" }
                                      },
                                      [
                                        service.quantity >= 0 &&
                                        service.uom &&
                                        service.discount >= 0 &&
                                        service.discount <= 100
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-sm btn-blue d-xs-block d-xl-none",
                                                attrs: {
                                                  disabled:
                                                    _vm.beforeEditCacheCatch ==
                                                      1 ||
                                                    _vm.beforeEditAfeCacheCatch ==
                                                      1,
                                                  type: "submit",
                                                  title:
                                                    "Save Service To Invoice"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addPreService(
                                                      service,
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-save"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        service.quantity >= 0 &&
                                        service.uom &&
                                        service.discount >= 0 &&
                                        service.discount <= 100
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "d-none d-xl-block btn btn-sm btn-blue",
                                                staticStyle: {
                                                  "margin-left": "15px"
                                                },
                                                attrs: {
                                                  disabled:
                                                    _vm.beforeEditCacheCatch ==
                                                      1 ||
                                                    _vm.beforeEditAfeCacheCatch ==
                                                      1 ||
                                                    !service.selected,
                                                  type: "submit",
                                                  title:
                                                    "Save Service To Invoice"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addPreService(
                                                      service,
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-save"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "div",
                            [
                              _vm.projectid
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "mt-3 mb-3 btn-sm mr-2 float-left",
                                      attrs: {
                                        disabled:
                                          _vm.beforeEditCacheCatch == 1 ||
                                          _vm.beforeEditAfeCacheCatch == 1,
                                        variant: "success",
                                        href: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showServiceModal(
                                            _vm.preServiceData.length,
                                            "name",
                                            "flag"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Add New Line "
                                      ),
                                      _c("i", { staticClass: "fa fa-plus" })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                "b-card",
                [
                  _c("h3", { staticClass: "d-inline mt-3" }, [
                    _vm._v("Service Lines "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "ml-2 fa fa fa-question-circle",
                      attrs: { title: "This is review of a preset." }
                    })
                  ]),
                  _c(
                    "b-row",
                    { staticStyle: { clear: "both" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "col-12 col-lg-12 mr-auto mt-2" },
                        [
                          _c("div", { staticClass: "table-responsive-sm" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table vendor-invoice-unassigned vendor-service-table"
                              },
                              [
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "d-none d-xl-table-header-group"
                                  },
                                  [
                                    _c("tr", [
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Service")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Code")
                                      ]),
                                      _c(
                                        "th",
                                        { staticClass: "border-0 datepickers" },
                                        [_vm._v("Start - End Date")]
                                      ),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("UOM")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Price")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Discounted Price/Unit")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Quantity")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Discount")
                                      ]),
                                      _c("th", { staticClass: "border-0" }, [
                                        _vm._v("Total")
                                      ]),
                                      _c("th", { staticClass: "border-0" })
                                    ])
                                  ]
                                ),
                                _vm._l(_vm.unassigned, function(service) {
                                  return _c("tbody", [
                                    _c(
                                      "tr",
                                      {
                                        staticClass:
                                          "service-rows mt-2 service-bg unassigned rt-wss-create-afe",
                                        class: {
                                          editing: service == _vm.editedLine
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "center price v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Service: " +
                                                        _vm._s(service.name)
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(service.name)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Service: " +
                                                      _vm._s(service.name)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mb-0 d-none d-xl-block"
                                                },
                                                [_vm._v(_vm._s(service.name))]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "center price v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Code: " +
                                                        _vm._s(service.type)
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [_vm._v(_vm._s(service.type))]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Code: " +
                                                      _vm._s(service.type)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-xl-block"
                                                },
                                                [_vm._v(_vm._s(service.type))]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "center price datepickers v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [_vm._v("Start - End Date")]
                                                ),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        service.dates.start,
                                                        "MM/DD/YYYY"
                                                      )
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        service.dates.end,
                                                        "MM/DD/YYYY"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "10px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          parseInt(
                                                            _vm
                                                              .$moment(
                                                                service.dates
                                                                  .end
                                                              )
                                                              .diff(
                                                                _vm.$moment(
                                                                  service.dates
                                                                    .start
                                                                ),
                                                                "days"
                                                              )
                                                          ) + 1
                                                        ) +
                                                        " Days)"
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                service == _vm.editedLine
                                                  ? _c("v-date-picker", {
                                                      staticClass:
                                                        "vc-calendar",
                                                      attrs: {
                                                        formats: _vm.formats,
                                                        mode: "range",
                                                        "show-caps": ""
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateEditQuantity(
                                                            service
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: service.dates,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            service,
                                                            "dates",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "service.dates"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "left price v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Unit Of Measurement: " +
                                                        _vm._s(service.uom[1])
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(service.uom[1])
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              service.serviceid
                                                ? _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "required",
                                                          expression:
                                                            "'required'"
                                                        },
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: service.uom,
                                                          expression:
                                                            "service.uom"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "w-100 rt-select",
                                                      class: {
                                                        "is-danger": _vm.errors.has(
                                                          "service.uom"
                                                        )
                                                      },
                                                      attrs: {
                                                        name: "service.uom",
                                                        placeholder: "Select"
                                                      },
                                                      on: {
                                                        change: [
                                                          function($event) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              service,
                                                              "uom",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.Pu_toggler(
                                                              service,
                                                              service.discount,
                                                              service.uom[0]
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            selected: ""
                                                          },
                                                          domProps: {
                                                            value: [
                                                              service.serviceid
                                                                .pu1,
                                                              service.serviceid
                                                                .uom1
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              service.serviceid
                                                                .uom1
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      service.serviceid.pu2
                                                        ? _c(
                                                            "option",
                                                            {
                                                              domProps: {
                                                                value: [
                                                                  service
                                                                    .serviceid
                                                                    .pu2,
                                                                  service
                                                                    .serviceid
                                                                    .uom2
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  service
                                                                    .serviceid
                                                                    .uom2
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      service.serviceid.pu3
                                                        ? _c(
                                                            "option",
                                                            {
                                                              domProps: {
                                                                value: [
                                                                  service
                                                                    .serviceid
                                                                    .pu3,
                                                                  service
                                                                    .serviceid
                                                                    .uom3
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  service
                                                                    .serviceid
                                                                    .uom3
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "price left v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Price: $" +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            service.pu
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            service.pu
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Price: $" +
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          service.uom[0]
                                                        )
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-xl-block"
                                                },
                                                [
                                                  _vm._v(
                                                    "$" +
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          service.uom[0]
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "price left v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Discounted Price/Unit:" +
                                                        _vm._s(
                                                          service.discounted_price_per_unit
                                                            ? _vm.formatPrice(
                                                                service.discounted_price_per_unit
                                                              )
                                                            : _vm._discount_price_per_unit(
                                                                service.discount,
                                                                service.uom[0]
                                                              )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        service.discounted_price_per_unit
                                                          ? _vm.formatPrice(
                                                              service.discounted_price_per_unit
                                                            )
                                                          : _vm._discount_price_per_unit(
                                                              service.discount,
                                                              service.uom[0]
                                                            )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Discounted Price/Unit:\n\n "
                                                  ),
                                                  service.name
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              service.discounted_price_per_unit,
                                                            expression:
                                                              "service.discounted_price_per_unit"
                                                          }
                                                        ],
                                                        staticStyle: {
                                                          width: "110px"
                                                        },
                                                        domProps: {
                                                          value:
                                                            service.discounted_price_per_unit
                                                        },
                                                        on: {
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                service,
                                                                "discounted_price_per_unit",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.Discount(
                                                                service,
                                                                service.discounted_price_per_unit,
                                                                service.uom[0]
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-xl-block"
                                                },
                                                [
                                                  service.name
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              service.discounted_price_per_unit,
                                                            expression:
                                                              "service.discounted_price_per_unit"
                                                          }
                                                        ],
                                                        staticStyle: {
                                                          width: "110px"
                                                        },
                                                        domProps: {
                                                          value:
                                                            service.discounted_price_per_unit
                                                        },
                                                        on: {
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                service,
                                                                "discounted_price_per_unit",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.Discount(
                                                                service,
                                                                service.discounted_price_per_unit,
                                                                service.uom[0]
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "price left v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Quantity: " +
                                                        _vm._s(
                                                          parseFloat(
                                                            service.quantity
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-inline-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        parseFloat(
                                                          service.quantity
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                /day|Day/.test(service.uom) &&
                                                _vm
                                                  .$moment(service.dates.end)
                                                  .diff(
                                                    _vm.$moment(
                                                      service.dates.start
                                                    ),
                                                    "days"
                                                  ) +
                                                  1 !=
                                                  service.quantity
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "ml-1" },
                                                      [
                                                        _c("i", {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover",
                                                              modifiers: {
                                                                hover: true
                                                              }
                                                            }
                                                          ],
                                                          staticClass:
                                                            "fa fa-exclamation-triangle",
                                                          attrs: {
                                                            title:
                                                              "Quantity does not equal sum between start and end dates (" +
                                                              (parseInt(
                                                                _vm
                                                                  .$moment(
                                                                    service
                                                                      .dates.end
                                                                  )
                                                                  .diff(
                                                                    _vm.$moment(
                                                                      service
                                                                        .dates
                                                                        .start
                                                                    ),
                                                                    "days"
                                                                  )
                                                              ) +
                                                                1) +
                                                              ")!"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: service.quantity,
                                                    expression:
                                                      "service.quantity"
                                                  }
                                                ],
                                                staticClass: "rt-round",
                                                class: {
                                                  warning:
                                                    /day|Day/.test(
                                                      service.uom
                                                    ) &&
                                                    _vm
                                                      .$moment(
                                                        service.dates.end
                                                      )
                                                      .diff(
                                                        _vm.$moment(
                                                          service.dates.start
                                                        ),
                                                        "days"
                                                      ) +
                                                      1 !=
                                                      service.quantity
                                                },
                                                attrs: { step: "0.01" },
                                                domProps: {
                                                  value: service.quantity
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      service,
                                                      "quantity",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              /day|Day/.test(service.uom) &&
                                              _vm
                                                .$moment(service.dates.end)
                                                .diff(
                                                  _vm.$moment(
                                                    service.dates.start
                                                  ),
                                                  "days"
                                                ) +
                                                1 !=
                                                service.quantity
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ml-1" },
                                                    [
                                                      _c("i", {
                                                        directives: [
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover",
                                                            modifiers: {
                                                              hover: true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "fa fa-exclamation-triangle",
                                                        attrs: {
                                                          title:
                                                            "Quantity does not equal sum between start and end (" +
                                                            (parseInt(
                                                              _vm
                                                                .$moment(
                                                                  service.dates
                                                                    .end
                                                                )
                                                                .diff(
                                                                  _vm.$moment(
                                                                    service
                                                                      .dates
                                                                      .start
                                                                  ),
                                                                  "days"
                                                                )
                                                            ) +
                                                              1) +
                                                            ")!"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "price left v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Discount: " +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            service.discount
                                                          )
                                                        ) +
                                                        "%"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          service.discount
                                                        )
                                                      ) + "%"
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                service.name
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            service.discount,
                                                          expression:
                                                            "service.discount"
                                                        }
                                                      ],
                                                      staticClass: "rt-round",
                                                      staticStyle: {
                                                        width: "110px"
                                                      },
                                                      attrs: {
                                                        type: "text",
                                                        id:
                                                          "" +
                                                          (service.serviceid
                                                            .ptn +
                                                            service.uom[0])
                                                      },
                                                      domProps: {
                                                        value: service.discount
                                                      },
                                                      on: {
                                                        input: [
                                                          function($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              service,
                                                              "discount",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.discounted(
                                                              service,
                                                              service.discount,
                                                              service.uom[0]
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      delay: "60000",
                                                      show:
                                                        service.discount >
                                                          100 ||
                                                        service.discount < 0,
                                                      target:
                                                        "" +
                                                        (service.serviceid.ptn +
                                                          service.uom[0]),
                                                      title: "Warning"
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Discount should be between 0%-100%"
                                                      )
                                                    ]),
                                                    _c("hr"),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Discounted price per unit should be between $0-price/unit"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "right v-align-middle"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editData(service)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Total: $" +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            service.quantity *
                                                              service.pu -
                                                              (service.discount /
                                                                100) *
                                                                service.quantity *
                                                                service.pu
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-xl-block"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            service.quantity *
                                                              service.pu -
                                                              (service.discount /
                                                                100) *
                                                                service.quantity *
                                                                service.pu
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Total: $" +
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          service.quantity *
                                                            service.pu -
                                                            (service.discount /
                                                              100) *
                                                              service.quantity *
                                                              service.pu
                                                        )
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-xl-block"
                                                },
                                                [
                                                  _vm._v(
                                                    "$" +
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          service.quantity *
                                                            service.pu -
                                                            (service.discount /
                                                              100) *
                                                              service.quantity *
                                                              service.pu
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c("td", [
                                          _c("div", { staticClass: "edit" }, [
                                            service.quantity >= 0 &&
                                            service.uom &&
                                            service.discount >= 0 &&
                                            service.discount <= 100
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "edit btn btn-sm btn-primary",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editService(
                                                          service
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-edit"
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "tr",
                                      {
                                        staticClass: "rt-wss-create-afe",
                                        class: {
                                          createAfe:
                                            service !=
                                            _vm.createdUnassignedServiceLine
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "p-0 show",
                                            attrs: { colspan: "100%" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-responsive-sm"
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "table no-bg afenum mb-0"
                                                  },
                                                  [
                                                    _c(
                                                      "thead",
                                                      {
                                                        staticClass: "rt-border"
                                                      },
                                                      [
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "afe-rows"
                                                          },
                                                          [
                                                            _c("th", {
                                                              staticClass:
                                                                "actions fit"
                                                            }),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "AFE / PO"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "\n                                %\n                                "
                                                                  ),
                                                                  _c("i", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-tooltip",
                                                                        rawName:
                                                                          "v-b-tooltip.hover",
                                                                        modifiers: {
                                                                          hover: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "fa fa-question-circle",
                                                                    attrs: {
                                                                      title:
                                                                        "AFE Percentage"
                                                                    }
                                                                  })
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #1 / Major"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #2 / Minor"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #3 / Description"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c("th", {
                                                              staticClass:
                                                                "center"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c("tbody", [
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "mt-1 fit btn btn-sm btn-danger",
                                                              attrs: {
                                                                type: "button",
                                                                title:
                                                                  "AFE Cancel"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cancelAfe()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times-circle"
                                                              })
                                                            ]
                                                          )
                                                        ]),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "d-xs-block d-xl-none"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "AFE / PO"
                                                                )
                                                              ]
                                                            ),
                                                            service ==
                                                            _vm.createdUnassignedServiceLine
                                                              ? _c("v-select", {
                                                                  attrs: {
                                                                    label:
                                                                      "afenum",
                                                                    options:
                                                                      _vm.createAfeData,
                                                                    value:
                                                                      _vm.afenum
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getcc1Create()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.createAfenum,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.createAfenum = $$v
                                                                    },
                                                                    expression:
                                                                      "createAfenum"
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c("td", [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "d-xs-block d-xl-none"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Percentage"
                                                              )
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.percentage,
                                                                expression:
                                                                  "percentage"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "rt-percent",
                                                            attrs: {
                                                              type: "number",
                                                              step: "any"
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.percentage
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.percentage =
                                                                  $event.target.value
                                                              }
                                                            }
                                                          })
                                                        ]),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "d-xs-block d-xl-none"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cost Code #1 / Major"
                                                                )
                                                              ]
                                                            ),
                                                            service ==
                                                            _vm.createdUnassignedServiceLine
                                                              ? _c("v-select", {
                                                                  attrs: {
                                                                    label:
                                                                      "ccone_code",
                                                                    options:
                                                                      _vm.cc1CreateData,
                                                                    value:
                                                                      _vm.ccone_code
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getcc2Create()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.createCC1Code,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.createCC1Code = $$v
                                                                    },
                                                                    expression:
                                                                      "createCC1Code"
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "d-xs-block d-xl-none"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cost Code #2 / Minor"
                                                                )
                                                              ]
                                                            ),
                                                            service ==
                                                            _vm.createdUnassignedServiceLine
                                                              ? _c("v-select", {
                                                                  attrs: {
                                                                    label:
                                                                      "cctwo_code",
                                                                    options:
                                                                      _vm.cc2CreateData,
                                                                    value:
                                                                      _vm.cctwo_code
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getcc3Create()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.createCC2Code,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.createCC2Code = $$v
                                                                    },
                                                                    expression:
                                                                      "createCC2Code"
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "d-xs-block d-xl-none"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cost Code #3 / Description"
                                                                )
                                                              ]
                                                            ),
                                                            service ==
                                                            _vm.createdUnassignedServiceLine
                                                              ? _c("v-select", {
                                                                  attrs: {
                                                                    label:
                                                                      "ccthree_code",
                                                                    options:
                                                                      _vm.cc3CreateData,
                                                                    value:
                                                                      _vm.ccthree_code
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.createCC3Code,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.createCC3Code = $$v
                                                                    },
                                                                    expression:
                                                                      "createCC3Code"
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "actions"
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm mt-1 btn-success mr-2",
                                                                attrs: {
                                                                  type: "button"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addAfeUnassignedLine(
                                                                      service
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                Add\n                                "
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-plus"
                                                                })
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm mt-1 btn-success",
                                                                attrs: {
                                                                  type: "button"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addAfeToAllInvoiceServices(
                                                                      service
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                Add To All\n                                "
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-plus"
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                _vm._l(_vm.merged, function(afe, index) {
                                  return _c(
                                    "tbody",
                                    [
                                      _vm._l(afe, function(
                                        service,
                                        serviceIndex
                                      ) {
                                        return _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "service-rows mt-2 service-bg rt-wss-create-afe",
                                            class: {
                                              editing: service == _vm.editedLine
                                            }
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "d-xs-none d-lg-block actions fit border-0",
                                                staticStyle: {
                                                  "line-height": "55px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-danger",
                                                    attrs: {
                                                      type: "button",
                                                      disabled:
                                                        _vm.disableEdits == 1
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeService(
                                                          service
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-minus"
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "center price v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Service: " +
                                                            _vm._s(service.name)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(service.name)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Service: " +
                                                            _vm._s(service.name)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mb-0 d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(service.name)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "center price v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Code: " +
                                                            _vm._s(service.type)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(service.type)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Code: " +
                                                            _vm._s(service.type)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(service.type)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "center datepickers v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Start - End Date"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            service.dates.start,
                                                            "MM/DD/YYYY"
                                                          )
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            service.dates.end,
                                                            "MM/DD/YYYY"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              parseInt(
                                                                _vm
                                                                  .$moment(
                                                                    service
                                                                      .dates.end
                                                                  )
                                                                  .diff(
                                                                    _vm.$moment(
                                                                      service
                                                                        .dates
                                                                        .start
                                                                    ),
                                                                    "days"
                                                                  )
                                                              ) + 1
                                                            ) +
                                                            " Days)"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    service == _vm.editedLine
                                                      ? _c("v-date-picker", {
                                                          staticClass:
                                                            "vc-calendar",
                                                          attrs: {
                                                            formats:
                                                              _vm.formats,
                                                            mode: "range",
                                                            "show-caps": ""
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateMergedEditQuantity(
                                                                service,
                                                                index,
                                                                serviceIndex
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              service.dates,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                service,
                                                                "dates",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "service.dates"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "left price v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Unit Of Measurement: " +
                                                            _vm._s(
                                                              service.uom[1]
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(service.uom[1])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    service.serviceid
                                                      ? _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              },
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  service.uom,
                                                                expression:
                                                                  "service.uom"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "w-100 rt-select",
                                                            class: {
                                                              "is-danger": _vm.errors.has(
                                                                "service.uom"
                                                              )
                                                            },
                                                            attrs: {
                                                              name:
                                                                "service.uom",
                                                              placeholder:
                                                                "Select"
                                                            },
                                                            on: {
                                                              change: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    service,
                                                                    "uom",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  return _vm.Pu_toggler(
                                                                    service,
                                                                    service.discount,
                                                                    service
                                                                      .uom[0]
                                                                  )
                                                                }
                                                              ]
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  selected: ""
                                                                },
                                                                domProps: {
                                                                  value: [
                                                                    service
                                                                      .serviceid
                                                                      .pu1,
                                                                    service
                                                                      .serviceid
                                                                      .uom1
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    service
                                                                      .serviceid
                                                                      .uom1
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            service.serviceid
                                                              .pu2
                                                              ? _c(
                                                                  "option",
                                                                  {
                                                                    domProps: {
                                                                      value: [
                                                                        service
                                                                          .serviceid
                                                                          .pu2,
                                                                        service
                                                                          .serviceid
                                                                          .uom2
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        service
                                                                          .serviceid
                                                                          .uom2
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            service.serviceid
                                                              .pu3
                                                              ? _c(
                                                                  "option",
                                                                  {
                                                                    domProps: {
                                                                      value: [
                                                                        service
                                                                          .serviceid
                                                                          .pu3,
                                                                        service
                                                                          .serviceid
                                                                          .uom3
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        service
                                                                          .serviceid
                                                                          .uom3
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "price left v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Price: $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Price: $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.uom[0]
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.uom[0]
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "price left v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Discounted Price/Unit :\n                            " +
                                                            _vm._s(
                                                              service.discounted_price_per_unit
                                                                ? _vm.formatPrice(
                                                                    service.discounted_price_per_unit
                                                                  )
                                                                : _vm._discount_price_per_unit(
                                                                    service.discount,
                                                                    service
                                                                      .uom[0]
                                                                  )
                                                            ) +
                                                            "   \n\n                             "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\n                          " +
                                                            _vm._s(
                                                              service.discounted_price_per_unit
                                                                ? _vm.formatPrice(
                                                                    service.discounted_price_per_unit
                                                                  )
                                                                : _vm._discount_price_per_unit(
                                                                    service.discount,
                                                                    service
                                                                      .uom[0]
                                                                  )
                                                            ) +
                                                            "   \n                          "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Discounted Price/Unit: \n                            "
                                                        ),
                                                        service.serviceid.name
                                                          ? _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    service.discounted_price_per_unit,
                                                                  expression:
                                                                    "service.discounted_price_per_unit"
                                                                }
                                                              ],
                                                              domProps: {
                                                                value:
                                                                  service.discounted_price_per_unit
                                                              },
                                                              on: {
                                                                input: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      service,
                                                                      "discounted_price_per_unit",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.Discount(
                                                                      service,
                                                                      service.discounted_price_per_unit,
                                                                      service
                                                                        .uom[0]
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        service.serviceid.name
                                                          ? _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    service.discounted_price_per_unit,
                                                                  expression:
                                                                    "service.discounted_price_per_unit"
                                                                }
                                                              ],
                                                              domProps: {
                                                                value:
                                                                  service.discounted_price_per_unit
                                                              },
                                                              on: {
                                                                input: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      service,
                                                                      "discounted_price_per_unit",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.Discount(
                                                                      service,
                                                                      service.discounted_price_per_unit,
                                                                      service
                                                                        .uom[0]
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "price left v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Quantity: " +
                                                            _vm._s(
                                                              parseFloat(
                                                                service.quantity
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-inline-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            parseFloat(
                                                              service.quantity
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    /day|Day/.test(
                                                      service.uom
                                                    ) &&
                                                    _vm
                                                      .$moment(
                                                        service.dates.end
                                                      )
                                                      .diff(
                                                        _vm.$moment(
                                                          service.dates.start
                                                        ),
                                                        "days"
                                                      ) +
                                                      1 !=
                                                      service.quantity
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-1"
                                                          },
                                                          [
                                                            _c("i", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "fa fa-exclamation-triangle",
                                                              attrs: {
                                                                title:
                                                                  "Quantity does not equal sum between start and end dates (" +
                                                                  (parseInt(
                                                                    _vm
                                                                      .$moment(
                                                                        service
                                                                          .dates
                                                                          .end
                                                                      )
                                                                      .diff(
                                                                        _vm.$moment(
                                                                          service
                                                                            .dates
                                                                            .start
                                                                        ),
                                                                        "days"
                                                                      )
                                                                  ) +
                                                                    1) +
                                                                  ")!"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            service.quantity,
                                                          expression:
                                                            "service.quantity"
                                                        }
                                                      ],
                                                      staticClass: "rt-round",
                                                      class: {
                                                        warning:
                                                          /day|Day/.test(
                                                            service.uom
                                                          ) &&
                                                          _vm
                                                            .$moment(
                                                              service.dates.end
                                                            )
                                                            .diff(
                                                              _vm.$moment(
                                                                service.dates
                                                                  .start
                                                              ),
                                                              "days"
                                                            ) +
                                                            1 !=
                                                            service.quantity
                                                      },
                                                      attrs: { step: "0.01" },
                                                      domProps: {
                                                        value: service.quantity
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            service,
                                                            "quantity",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    /day|Day/.test(
                                                      service.uom
                                                    ) &&
                                                    _vm
                                                      .$moment(
                                                        service.dates.end
                                                      )
                                                      .diff(
                                                        _vm.$moment(
                                                          service.dates.start
                                                        ),
                                                        "days"
                                                      ) +
                                                      1 !=
                                                      service.quantity
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-1"
                                                          },
                                                          [
                                                            _c("i", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "fa fa-exclamation-triangle",
                                                              attrs: {
                                                                title:
                                                                  "Quantity does not equal sum between start and end (" +
                                                                  (parseInt(
                                                                    _vm
                                                                      .$moment(
                                                                        service
                                                                          .dates
                                                                          .end
                                                                      )
                                                                      .diff(
                                                                        _vm.$moment(
                                                                          service
                                                                            .dates
                                                                            .start
                                                                        ),
                                                                        "days"
                                                                      )
                                                                  ) +
                                                                    1) +
                                                                  ")!"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "price left v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Discount: " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.discount
                                                              )
                                                            ) +
                                                            "%"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              service.discount
                                                            )
                                                          ) + "%"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    service.name
                                                      ? _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                service.discount,
                                                              expression:
                                                                "service.discount"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "rt-round",
                                                          attrs: {
                                                            type: "text",
                                                            id:
                                                              "" +
                                                              (service.serviceid
                                                                .ptn +
                                                                service.uom[0])
                                                          },
                                                          domProps: {
                                                            value:
                                                              service.discount
                                                          },
                                                          on: {
                                                            input: [
                                                              function($event) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  service,
                                                                  "discount",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function($event) {
                                                                return _vm.discounted(
                                                                  service,
                                                                  service.discount,
                                                                  service.uom[0]
                                                                )
                                                              }
                                                            ]
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          delay: "60000",
                                                          show:
                                                            service.discount >
                                                              100 ||
                                                            service.discount <
                                                              0,
                                                          target:
                                                            "" +
                                                            (service.serviceid
                                                              .ptn +
                                                              service.uom[0]),
                                                          title: "Warning"
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Discount should be between 0%-100%"
                                                          )
                                                        ]),
                                                        _c("hr"),
                                                        _c("span", [
                                                          _vm._v(
                                                            "Discounted price per unit should be between $0-price/unit"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "right v-align-middle",
                                                attrs: {
                                                  title:
                                                    "please remove the AFE cost code from the service and then try to edit the fields."
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "view" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Total: $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.quantity *
                                                                  service.pu -
                                                                  (service.discount /
                                                                    100) *
                                                                    service.quantity *
                                                                    service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.quantity *
                                                                  service.pu -
                                                                  (service.discount /
                                                                    100) *
                                                                    service.quantity *
                                                                    service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Total: $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.quantity *
                                                                  service.pu -
                                                                  (service.discount /
                                                                    100) *
                                                                    service.quantity *
                                                                    service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-xl-block"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                service.quantity *
                                                                  service.pu -
                                                                  (service.discount /
                                                                    100) *
                                                                    service.quantity *
                                                                    service.pu
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  service.quantity >= 0 &&
                                                  service.uom &&
                                                  service.discount >= 0 &&
                                                  service.discount <= 100
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "edit btn btn-sm btn-primary",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.editService(
                                                                service
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-edit"
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      }),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "p-0",
                                            attrs: { colspan: "100%" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-responsive-sm"
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "table no-bg afenum mb-0"
                                                  },
                                                  [
                                                    _c(
                                                      "thead",
                                                      {
                                                        staticClass:
                                                          "rt-border  d-none d-xl-table-header-group"
                                                      },
                                                      [
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "afe-rows"
                                                          },
                                                          [
                                                            _c("th", {
                                                              staticClass:
                                                                "actions fit"
                                                            }),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "AFE / PO"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center fit"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v("% "),
                                                                  _c("i", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-tooltip",
                                                                        rawName:
                                                                          "v-b-tooltip.hover",
                                                                        modifiers: {
                                                                          hover: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "fa fa-question-circle",
                                                                    attrs: {
                                                                      title:
                                                                        "AFE Percentage"
                                                                    }
                                                                  })
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #1 / Major"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #2 / Minor"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "center"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Cost Code #3 / Description"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c("th", {
                                                              staticClass:
                                                                "centerx"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "tbody",
                                                      [
                                                        _vm._l(
                                                          afe[0].afes,
                                                          function(
                                                            line,
                                                            aIndex
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "rt-wss-create-afe rt-mobile-indent",
                                                                class: {
                                                                  editingAfe:
                                                                    line ==
                                                                    _vm.editedAfeLine
                                                                }
                                                              },
                                                              [
                                                                _c("td", {
                                                                  staticClass:
                                                                    "d-xs-none d-lg-block actions fit border-0"
                                                                }),
                                                                _c("td", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "view"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h4",
                                                                        {
                                                                          staticClass:
                                                                            "d-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "AFE / PO"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-primary d-inline-block d-xl-none btn-sm float-right",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              ;(_vm.editAfe = 1),
                                                                                _vm.editAfeLine(
                                                                                  line,
                                                                                  index,
                                                                                  aIndex,
                                                                                  _vm.afes,
                                                                                  afe
                                                                                )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-edit"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              ;(_vm.editAfe = 1),
                                                                                _vm.editAfeLine(
                                                                                  line,
                                                                                  index,
                                                                                  aIndex,
                                                                                  _vm.afes,
                                                                                  afe
                                                                                )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-chevron-circle-right"
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                line.afenum
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.editAfe = 0
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "AFE / PO"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      line ==
                                                                      _vm.editedAfeLine
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "afenum",
                                                                                options:
                                                                                  _vm.serviceChainData,
                                                                                value:
                                                                                  _vm.afenum
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getcc1(
                                                                                    index,
                                                                                    aIndex,
                                                                                    line
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  line.afenum,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    line,
                                                                                    "afenum",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "line.afenum"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "view",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          ;(_vm.editAfe = 1),
                                                                            _vm.editAfeLine(
                                                                              line,
                                                                              index,
                                                                              aIndex,
                                                                              _vm.afes,
                                                                              afe
                                                                            )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Percentage"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                                    " +
                                                                          _vm._s(
                                                                            line.percentage
                                                                          ) +
                                                                          "\n                                  "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Percentage"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm.percentage,
                                                                              expression:
                                                                                "percentage"
                                                                            }
                                                                          ],
                                                                          staticClass:
                                                                            "rt-percent",
                                                                          attrs: {
                                                                            type:
                                                                              "number",
                                                                            step:
                                                                              "any"
                                                                          },
                                                                          domProps: {
                                                                            value:
                                                                              _vm.percentage
                                                                          },
                                                                          on: {
                                                                            input: function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              ) {
                                                                                return
                                                                              }
                                                                              _vm.percentage =
                                                                                $event.target.value
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "view",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          ;(_vm.editAfe = 1),
                                                                            _vm.editAfeLine(
                                                                              line,
                                                                              index,
                                                                              aIndex,
                                                                              _vm.afes,
                                                                              afe
                                                                            )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #1 / Major"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                                    " +
                                                                          _vm._s(
                                                                            line.ccone_code
                                                                          ) +
                                                                          "\n                                  "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.editAfe = 0
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #1 / Major"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      line ==
                                                                      _vm.editedAfeLine
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "ccone_code",
                                                                                options:
                                                                                  _vm.cc1Data,
                                                                                value:
                                                                                  _vm.ccone_code
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getcc2(
                                                                                    index,
                                                                                    aIndex,
                                                                                    line
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  line.ccone_code,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    line,
                                                                                    "ccone_code",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "line.ccone_code"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "view",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          ;(_vm.editAfe = 1),
                                                                            _vm.editAfeLine(
                                                                              line,
                                                                              index,
                                                                              aIndex,
                                                                              _vm.afes,
                                                                              afe
                                                                            )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #2 / Minor"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                                    " +
                                                                          _vm._s(
                                                                            line.cctwo_code
                                                                          ) +
                                                                          "\n                                  "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.editAfe = 0
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #2 / Minor"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      line ==
                                                                      _vm.editedAfeLine
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "cctwo_code",
                                                                                options:
                                                                                  _vm.cc2Data,
                                                                                value:
                                                                                  _vm.cctwo_code
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getcc3(
                                                                                    index,
                                                                                    aIndex,
                                                                                    line
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  line.cctwo_code,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    line,
                                                                                    "cctwo_code",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "line.cctwo_code"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "view",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          ;(_vm.editAfe = 1),
                                                                            _vm.editAfeLine(
                                                                              line,
                                                                              index,
                                                                              aIndex,
                                                                              _vm.afes,
                                                                              afe
                                                                            )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #3 / Description"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      typeof line.ccthree_code ==
                                                                        "string" &&
                                                                      !line.ccthree_code.includes(
                                                                        "---"
                                                                      )
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  line.ccthree_code
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.editAfe = 0
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h6",
                                                                        {
                                                                          staticClass:
                                                                            "d-xs-block d-xl-none"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cost Code #3 / Description"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      line ==
                                                                      _vm.editedAfeLine
                                                                        ? _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "ccthree_code",
                                                                                options:
                                                                                  _vm.cc3Data,
                                                                                value:
                                                                                  _vm.ccthree_code
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  line.ccthree_code,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    line,
                                                                                    "ccthree_code",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "line.ccthree_code"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "actions"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "edit"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-primary",
                                                                            attrs: {
                                                                              type:
                                                                                "button"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.editAddAfeToInvoice(
                                                                                  line,
                                                                                  afe,
                                                                                  index,
                                                                                  aIndex,
                                                                                  _vm.afes
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-edit"
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "rt-wss-create-afe",
                                                            class: {
                                                              createAfe:
                                                                afe !=
                                                                _vm.createdServiceLine
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "show"
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-tooltip",
                                                                        rawName:
                                                                          "v-b-tooltip.hover",
                                                                        modifiers: {
                                                                          hover: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "fit btn btn-sm btn-danger",
                                                                    attrs: {
                                                                      type:
                                                                        "button",
                                                                      title:
                                                                        "AFE Cancel"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancelAfe()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-times-circle"
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "show"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "d-xs-block d-xl-none"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "AFE / PO"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  afe ==
                                                                  _vm.createdServiceLine
                                                                    ? _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "afenum",
                                                                            options:
                                                                              _vm.createAfeData,
                                                                            value:
                                                                              _vm.afenum
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getcc1Create()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.createAfenum,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.createAfenum = $$v
                                                                            },
                                                                            expression:
                                                                              "createAfenum"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "show"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "d-xs-block d-xl-none"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Percentage"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm.percentage,
                                                                        expression:
                                                                          "percentage"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "rt-percent",
                                                                    attrs: {
                                                                      type:
                                                                        "number",
                                                                      step:
                                                                        "any"
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.percentage
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.percentage =
                                                                          $event.target.value
                                                                      }
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "show"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "d-xs-block d-xl-none"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Cost Code #1 / Major"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  afe ==
                                                                  _vm.createdServiceLine
                                                                    ? _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "ccone_code",
                                                                            options:
                                                                              _vm.cc1CreateData,
                                                                            value:
                                                                              _vm.ccone_code
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getcc2Create()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.createCC1Code,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.createCC1Code = $$v
                                                                            },
                                                                            expression:
                                                                              "createCC1Code"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "show"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "d-xs-block d-xl-none"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Cost Code #2 / Minor"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  afe ==
                                                                  _vm.createdServiceLine
                                                                    ? _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "cctwo_code",
                                                                            options:
                                                                              _vm.cc2CreateData,
                                                                            value:
                                                                              _vm.cctwo_code
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getcc3Create()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.createCC2Code,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.createCC2Code = $$v
                                                                            },
                                                                            expression:
                                                                              "createCC2Code"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "show"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "d-xs-block d-xl-none"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Cost Code #3 / Description"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  afe ==
                                                                  _vm.createdServiceLine
                                                                    ? _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "ccthree_code",
                                                                            options:
                                                                              _vm.cc3CreateData,
                                                                            value:
                                                                              _vm.ccthree_code
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.createCC3Code,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.createCC3Code = $$v
                                                                            },
                                                                            expression:
                                                                              "createCC3Code"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "actions"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "show"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm btn-success mr-2",
                                                                        attrs: {
                                                                          type:
                                                                            "button"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.addAfeLine(
                                                                              afe
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Add "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-plus"
                                                                          }
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm btn-success",
                                                                        attrs: {
                                                                          type:
                                                                            "button"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.addAfeToAllInvoiceServices(
                                                                              index,
                                                                              afe
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Add To All "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-plus"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "col-12 ml-auto", attrs: { lg: "6" } },
                        [
                          _c(
                            "table",
                            { staticClass: "mt-1 table table-clear" },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [_vm._v("Subtotal")])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(_vm.formatPrice(_vm.subtotal))
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [_vm._v("GST")])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.gst,
                                          expression: "gst"
                                        }
                                      ],
                                      staticClass: "days form-control",
                                      attrs: {
                                        readonly: "",
                                        type: "number",
                                        min: "0",
                                        max: "100",
                                        step: "any"
                                      },
                                      domProps: { value: _vm.gst },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.gst = $event.target.value
                                        }
                                      }
                                    }),
                                    _vm._v(" %")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [_vm._v("Tax")])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(_vm.formatPrice(_vm.gstsubtotal))
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [_vm._v("Total")])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.formatPrice(_vm.totalinvoice)
                                          )
                                      )
                                    ])
                                  ])
                                ]),
                                _c("tr", [_c("hr")]),
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [_vm._v("Subtotal")])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(_vm.formatPrice(_vm.subtotal))
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "left" }, [
                                    _c("strong", [
                                      _vm._v("Quick Pay Discount "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          }
                                        ],
                                        staticClass:
                                          "ml-2 fa fa fa-question-circle",
                                        attrs: {
                                          title:
                                            "Discount given to get paid quicker"
                                        }
                                      })
                                    ])
                                  ]),
                                  _c("td", { staticClass: "right" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.quickpay,
                                          expression: "quickpay"
                                        }
                                      ],
                                      staticClass: "days form-control",
                                      attrs: {
                                        type: "number",
                                        readonly: "",
                                        min: "0",
                                        max: "100",
                                        step: "any"
                                      },
                                      domProps: { value: _vm.quickpay },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.quickpay = $event.target.value
                                        }
                                      }
                                    }),
                                    _vm._v(" %\n                  ")
                                  ])
                                ]),
                                _vm.quickpay > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "left" }, [
                                        _c("strong", [
                                          _vm._v("Quick Pay Discount Total")
                                        ])
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.formatPrice(_vm.qpdiscount)
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.quickpay > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "left" }, [
                                        _c("strong", [
                                          _vm._v("Quick Pay Subtotal")
                                        ])
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                _vm.quickpaysubtotal
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.quickpay > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "left" }, [
                                        _c("strong", [_vm._v("Quick Pay Tax")])
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.formatPrice(_vm.qpgstsubtotal)
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.quickpay > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "left" }, [
                                        _c("strong", [
                                          _vm._v("Quick Pay Net Days "),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "ml-2 fa fa fa-question-circle",
                                            attrs: {
                                              title:
                                                "Days given for quickpay payment"
                                            }
                                          })
                                        ])
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.days,
                                              expression: "days"
                                            }
                                          ],
                                          staticClass: "form-control days",
                                          attrs: {
                                            readonly: "",
                                            type: "number",
                                            min: "0"
                                          },
                                          domProps: { value: _vm.days },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.days = $event.target.value
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.quickpay > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "left" }, [
                                        _c("strong", [
                                          _vm._v("Quick Pay Total")
                                        ])
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _c("strong", [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  _vm.quickpaysubtotal +
                                                    _vm.qpgstsubtotal
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "0.75rem" } },
                            [
                              _c("h5", [
                                _vm._v("Comments "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "ml-2 fa fa fa-question-circle",
                                  attrs: {
                                    title:
                                      "Comments will be seen by all user levels and be attached on invoice"
                                  }
                                })
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comments,
                                    expression: "comments"
                                  }
                                ],
                                staticClass:
                                  "w-100 rounded border mt-2 mb-4 form-control",
                                attrs: {
                                  name: "comments",
                                  id: "",
                                  readonly: "",
                                  rows: "3"
                                },
                                domProps: { value: _vm.comments },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.comments = $event.target.value
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.invoiceError,
                                      expression: "invoiceError"
                                    }
                                  ],
                                  staticClass: "d-block"
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.invoiceError,
                                        expression: "invoiceError"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.invoiceError,
                                          expression: "invoiceError"
                                        }
                                      ],
                                      staticClass: "help"
                                    },
                                    [
                                      _vm._v(
                                        "Invoice Error. Please fix invoice or contact admin."
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }